<template>
    <page title="Caixa" v-if="fechamento">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'financeiro'}">Financeiro</v-breadcrumbs-item>
        </v-breadcrumbs>
        <div slot="menu"  v-if="fechamento">
            <v-item-group style="height: max-content; border: solid thin rgba(0, 0, 0, 0.12); border-radius: 6px; display: inline-flex" >
                <v-btn tile depressed color="white"
                       @click="openChangeCaixaDialog"
                       style="border-top-left-radius: inherit;border-bottom-left-radius: inherit;">
                    <span>Fechamento: {{fechamento.nome}}</span>
                    <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
                <v-menu bottom offset-y open-on-hover v-if="fechamento.id > 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn tile depressed
                               v-bind="attrs" v-on="on">
                            <v-icon left>mdi-table-arrow-down</v-icon>
                            Relatórios
                            <v-icon right>mdi-menu-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="downloadRelatorioPorFechamento(fechamento.id, 'completo')" >
                            <v-list-item-title>Completo</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="downloadRelatorioPorFechamento(fechamento.id, 'detalhado')" >
                            <v-list-item-title>Detalhado</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="downloadRelatorioPorFechamento(fechamento.id, 'agrupado')" >
                            <v-list-item-title>Agrupado</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="downloadRelatorioPorFechamento(fechamento.id, 'divisao')" >
                            <v-list-item-title>Divisão</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn v-if="fechamento.id === -1" tile depressed @click="fecharCaixa" color="blue accent-4 white--text"
                       style="border-top-right-radius: inherit;border-bottom-right-radius: inherit;">
                    <v-icon left>mdi-file-table</v-icon> Fechar caixa
                </v-btn>
            </v-item-group>
<!--            <v-btn class="ml-2" text outlined :to="{name:'conta'}">Conta</v-btn>-->
        </div>
        <div class="d-flex flex-column flex-sm-row flex-wrap mt-6  mb-6">
            <v-alert  class="text-right mb-sm-0 mr-sm-4 py-0" text color="red" >
                <span class="overline">Total Despesas</span>
                <span class="headline d-block">
                    {{totalDespesas | formatNumber}}
                </span>
            </v-alert>

            <v-alert class="text-right mb-sm--0 mr-sm-4 py-0" text color="blue" >
                <span class="overline">Total Receita</span>
                <span class="headline d-block">
                    {{totalReceitas | formatNumber}}
                </span>
            </v-alert>

            <v-alert class="text-right mb-sm--0 mr-sm-4 py-0" text color="teal">
                <span class="overline">Total Líquido</span>
                <span class="headline d-block">
                    {{totalLiquido | formatNumber}}
                </span>
            </v-alert>
        </div>

        <v-btn small class="mb-2 "
               :disabled="fechamento.id > 0" @click="novaDespesa">
            <v-icon left>mdi-minus</v-icon>Nova Despesa
        </v-btn>

<!--        <v-btn small class="mb-2 ml-4"
               :disabled="fechamento.id > 0" @click="novaReceita">
            <v-icon left>mdi-plus</v-icon>Nova Receita
        </v-btn>-->

        <div style="position: relative">
            <v-simple-table >
                <template v-slot:default>
                    <thead>
                    <tr style="white-space: nowrap">
<!--                        <th class="text-left">
                            Criado em
                        </th>-->
                        <th class="text-left">
                            Categoria
                        </th>
                        <th class="text-left">
                            Item
                        </th>
                        <th class="text-left" >
                            Descrição
                        </th>
                        <th class="text-left">
                            Valor
                        </th>
                        <th class="text-left" style="background-color: rgba(129,199,132, 0.2)">
                            Pagamento
                        </th>
                        <th class="text-left">

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(movimento, index) in movimentosOrdenados" :key="index" style="white-space: nowrap">
                        <td>
                            <v-chip color="teal lighten-4" x-small  label
                                    class="text-uppercase font-weight-medium">
                                {{movimento.item.categoria.nome}}
                            </v-chip>
                        </td>
                        <td>{{movimento.item.nome}}</td>
                        <td>
                            <template v-if="movimento.item.id === 29">
                                <a href="" @click.prevent="showNotasFiscais(movimento.id)">
                                    {{movimento.descricao}}
                                </a>
                            </template>
                            <template v-else>
                                {{movimento.descricao}}
                            </template>
                        </td>
                        <td>
                            <span :class="movimento.valor > 0 ? 'indigo--text' : 'red--text'">
                                {{ movimento.valor | formatNumber }}
                            </span>
                        </td>
                        <td style="background-color: rgba(129,199,132, 0.2)">
                            <template v-if="movimento.pagamento">
                                {{ movimento.pagamento.dataPagamento | moment("DD/MM/YYYY") }} - {{movimento.pagamento.formaPagamento.nome}}
                            </template>
                            <v-btn v-else rounded color="orange" dark x-small
                                   @click="efetuarPagamento(movimento)">
                                Lançar Pagamento
                            </v-btn>
                            <v-icon v-if="!movimento.temDivergenciaComTransacoes"
                                    small class="green--text float-right" title="Verificado">mdi-check-circle</v-icon>
                            <v-icon v-else
                                    small class="orange--text float-right" title="Divergência">mdi-alert</v-icon>
                        </td>
                        <td class="text-right">
                            <v-menu bottom left offset-y v-if="getAnexos(movimento).length > 0" >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  icon v-bind="attrs" v-on="on">
                                        <v-icon small>mdi-paperclip</v-icon>
                                    </v-btn>
                                </template>
                                <v-list max-width="300">
                                    <template v-for="(arquivo, index) in getAnexos(movimento)">
                                        <v-list-item dense :key="index" @click="downloadArquivo(arquivo.id)" >
                                            <v-list-item-content class="py-2">
                                                <v-list-item-title class="font-weight-regular"  :title="arquivo.nome">
                                                    {{arquivo.nome}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle >
                                                    <template v-for="tag in arquivo.tags">
                                                        <v-chip x-small label color="blue" text-color="white"
                                                                :title="arquivo.tags.map(t => t.nome).join(', ')"
                                                                class="mr-1 px-2" style="height: 14px; font-size: 10px">
                                                            {{tag.nome}}
                                                        </v-chip>
                                                    </template>

                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle class="font-weight-regular" style="font-size: 10px">
                                                    <span v-if="arquivo.createdAt">Criado em {{arquivo.createdAt | moment('DD-MM-YYYY [às] HH:mm')}}</span>
                                                    <span v-else>Arquivo não salvo ainda</span>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action class="my-0" title="">
                                                <div>
                                                    <v-btn icon
                                                           v-if="canViewFile(arquivo.nome)"
                                                           @click.stop="viewArquivo(arquivo.id)">
                                                        <v-icon small>mdi-eye</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-menu>
                            <v-btn icon @click="viewMovimentacao(movimento)">
                                <v-icon small>mdi-eye</v-icon>
                            </v-btn>
                            <v-btn icon color="red" :disabled="movimento.item.id === 29 || fechamento.dataFechamento !== null"
                                   @mousedown.stop @touchstart.native.stop
                                   @click.stop="removeMovimentacao(movimento.id, index)">
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
            <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="secondary" />
        </div>

        <caixas-dialog ref="caixasDialog" @selected="changeCaixa" />
        <nova-despesa-dialog ref="novaDespesaDialog" @saved="onMovimentacaoSaved" />
        <nova-receita-dialog ref="novaReceitaDialog" @saved="onMovimentacaoSaved" />
        <efetuar-pagamento-dialog ref="efetuarPagamentoDialog" @saved="onPagamentoSaved" />
        <movimentacao-notas-fiscais-dialog ref="movimentacaoNotasFiscaisDialog" />
        <fechar-caixa-dialog ref="fecharCaixaDialog" @saved="onFechamentoCriado" />
        <view-movimentacao-dialog ref="viewMovimentacaoDialog" @edited="onMovimentacaoEdited" />
    </page>
</template>

<script>
// @ts-ignore
import page from "../../components/Page";
import CaixasDialog from "./CaixasDialog";
import NovaDespesaDialog from "./NovaDespesaDialog";
import gql from "graphql-tag";
import EfetuarPagamentoDialog from "./EfetuarPagamentoDialog";
import MovimentacaoNotasFiscaisDialog from "./MovimentacaoNotasFiscaisDialog";
import FecharCaixaDialog from "./FecharCaixaDialog";
import {getExtensionFile, getIcon, getIconColor, canViewFile} from "@/assets/FileUtils";
import NovaReceitaDialog from "@/views/financeiro/NovaReceitaDialog";
import ViewMovimentacaoDialog from "@/views/financeiro/ViewMovimentacaoDialog";

export default {
    name: "Index",
    components: {
        ViewMovimentacaoDialog,
        NovaReceitaDialog,
        FecharCaixaDialog,
        MovimentacaoNotasFiscaisDialog,
        EfetuarPagamentoDialog,
        NovaDespesaDialog,
        CaixasDialog,
        page,
    },
    data() {
        return {
            loading: true,
            fechamentos: [],
            fechamento: null,
            movimentos: [],
        }
    },
    computed: {
        totalDespesas(){
            if(this.fechamento.dataFechamento){
                return this.fechamento.valorTotalDespesas;
            }
            if(this.loading){
                return 0
            }
            let total = 0;
            this.movimentos.filter(movimento => movimento.valor < 0).forEach(movimento => {
                total += movimento.valor;
            })
            return total * -1;
        },
        totalReceitas(){
            if(this.fechamento.dataFechamento){
                return this.fechamento.valorTotalReceitas;
            }
            if(this.loading){
                return 0
            }
            let total = 0;
            this.movimentos.filter(movimento => movimento.valor > 0).forEach(movimento => {
                total += movimento.valor;
            })
            return total;
        },
        totalLiquido(){
            if(this.fechamento.dataFechamento){
                return this.fechamento.valorTotalLiquido;
            }
            return this.totalReceitas - this.totalDespesas;
        },
        movimentosOrdenados(){
            return this.movimentos.sort((a, b) => {
                if(!a.pagamento){
                    return -1
                }else if (!b.pagamento){
                    return 1
                }
                var data1 = this.$moment(a.pagamento.dataPagamento);
                var data2 = this.$moment(b.pagamento.dataPagamento);

                if (data1.isBefore(data2)) return -1;
                if (data1.isAfter(data2)) return 1;

                var createdAt1 = this.$moment(a.createdAt);
                var createdAt2 = this.$moment(b.createdAt);

                if (createdAt1.isBefore(createdAt2)) return -1;
                if (createdAt1.isAfter(createdAt2)) return 1;
                return 0;
            })
        }
    },
    watch:{
        fechamento(value){
            if(!this.$route.params.id){
                this.$router.replace({params:{id: value.id}});
                return
            }
            this.$router.push({params:{id: value.id}});
        },
        $route(to, from) {
            /*if(from.query.fechamento === undefined){
                this.getMovimentacoes()
            }else{
                this.getMovimentacoesPorFechamento()
            }*/
            if(to.params.id){
                this.fechamento = this.fechamentos.find(value => value.id === parseInt(to.params.id))
                document.title = "Caixa "+this.fechamento.nome+" - JF Assessoria Rural"
                this.getMovimentacoesPorFechamento(to.params.id)
            }
        }
    },
    methods: {
        getIcon, getIconColor, getExtensionFile, canViewFile,
        openChangeCaixaDialog(){
            if(this.fechamentos.length === 0){
                return
            }
            this.$refs.caixasDialog.openDialog(this.fechamentos, this.fechamento);
        },
        changeCaixa(fechamento){
            this.fechamento = fechamento;
            document.title = "Caixa "+this.fechamento.nome+" - JF Assessoria Rural"
        },
        novaDespesa(){
            this.$refs.novaDespesaDialog.openDialog();
        },
        onMovimentacaoSaved(movimentacao, editMode){
            if(editMode){
                let movimentoIndex = this.movimentos.findIndex(m => m.id === movimentacao.id);
                this.$set(this.movimentos, movimentoIndex, movimentacao)
            }else{
                this.movimentos.push(movimentacao)
            }
        },
        novaReceita(){
            this.$refs.novaReceitaDialog.openDialog();
        },
        viewMovimentacao(movimento){
            this.$refs.viewMovimentacaoDialog.openDialog(movimento.id, this.fechamento.dataFechamento === null);
        },
        onMovimentacaoEdited(movimento){
            let movimentoEncontradoIndex = this.movimentos.findIndex(m => m.id === movimento.id);
            if(movimentoEncontradoIndex > -1){
                this.$set(this.movimentos, movimentoEncontradoIndex, movimento)
            }
        },
        efetuarPagamento(movimento){
            this.$refs.efetuarPagamentoDialog.openDialog(movimento);
        },
        onPagamentoSaved(pagamento){
            let movimentoEncontrado = this.movimentos.find(movimento => movimento.id === pagamento.movimentacao.id);
            delete pagamento.movimentacao;
            if(movimentoEncontrado){
                this.$set(movimentoEncontrado, 'pagamento', pagamento)
            }
        },
        getFechamentos(){
            return new Promise(resolve => {
                this.$apollo.query({
                    query : gql`query {
                    fechamentos{
                        id
                        nome
                        dataFechamento
                        valorTotalDespesas
                        valorTotalReceitas
                        valorTotalLiquido
                    }
                }`,
                }).then((result) => {
                    this.fechamentos = result.data.fechamentos;
                    resolve();
                })
            })

        },
        getMovimentacoesPorFechamento(id){
            this.loading = true;
            this.$apollo.query({
                query : gql`query($id: Int!) {
                    movimentacoes:movimentacoesPorFechamento(id: $id){
                        id
                        descricao
                        valor
                        createdAt
                        temDivergenciaComTransacoes
                        item{
                            id
                            nome
                            categoria{
                                id
                                nome
                                tipo{
                                    id
                                }
                            }
                        }
                        pagamento{
                            id
                            dataPagamento
                            formaPagamento{
                                id
                                nome
                            }
                            arquivos{
                                id
                                nome
                                createdAt
                                tags{
                                    id
                                    nome
                                }
                            }
                        }
                        arquivos{
                            id
                            nome
                            createdAt
                            tags{
                                id
                                nome
                            }
                        }
                    }
                }`,
                variables: {
                    id
                }
            }).then((result) => {
                this.movimentos = result.data.movimentacoes;
            }).finally(()=>{
                this.loading = false;
            });
        },
        removeMovimentacao(id, index) {
            this.$dialog.confirm({
                text: 'Deseja realmente apagar esta movimentação?',
                title: 'Atenção',
            }).then((result) => {
                if (result) {
                    this.$apollo.mutate({
                        mutation: gql`mutation ($id: Int!){
                            deleteMovimentacao(id: $id)
                        }`,
                        variables: {id},
                    }).then(() => {
                        this.movimentos.splice(index, 1);
                    });
                }
            });
        },
        showNotasFiscais(id){
            this.$refs.movimentacaoNotasFiscaisDialog.openDialog(id)
        },
        fecharCaixa(){
            this.$refs.fecharCaixaDialog.openDialog()
        },
        onFechamentoCriado(fechamento){
            this.getFechamentos().then(()=>{
                this.getMovimentacoesPorFechamento(-1)
            })
        },
        getAnexos(movimento){
            let arquivos = [];
            arquivos = arquivos.concat(movimento.arquivos)
            if(movimento.pagamento){
                arquivos = arquivos.concat(movimento.pagamento.arquivos)
            }
            return arquivos;
        },
        viewArquivo(id){
            this.$axios.get('/financeiro/arquivo/' + id, {responseType: 'blob'}).then((response) => {
                const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                let extension = this.getExtensionFile(fileName);
                let options = {};
                switch (extension) {
                    case 'pdf':
                        options.type = 'application/pdf';
                        break
                    case 'jpg':
                        options.type = 'image/jpg';
                        break
                }
                let blob = new Blob([response.data], options)
                let fileURL = URL.createObjectURL(blob);
                window.open(fileURL)
            }).catch(() => {
                this.$dialog.error({
                    text: 'Não foi possível abrir o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
        downloadArquivo(id) {
            this.$axios.get('/financeiro/arquivo/' + id, {responseType: 'blob'}).then((response) => {
                const fileLink = document.createElement('a');
                fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                const fileName = response.headers['content-disposition'].split('filename=')[1].replace(/^"|"$/g, '');
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(() => {
                this.$dialog.error({
                    text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
        downloadRelatorioPorFechamento(id, tipo) {
            //this.downloadingDanfe = true;
            this.$axios.get('/financeiro/fechamento/' + id + '/relatorio/' + tipo, {responseType: 'blob'}).then((response) => {
                const fileLink = document.createElement('a');
                fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                const fileName = response.headers['content-disposition'].split('filename=')[1];
                fileLink.setAttribute('download', fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                //this.downloadingDanfe = false;
            }).catch(() => {
                //this.downloadingDanfe = false;
                this.$dialog.error({
                    text: 'Não foi possível baixar o arquivo. Contate o desenvolvedor.',
                    title: 'Ops',
                });

            });
        },
        /*valorConfereComTransacoes(movimentacao){
            let valorTransacoes = 0;
            movimentacao.pagamento.ofxTransactions.forEach(t => {
                valorTransacoes += t.trnamt;
            })

            valorTransacoes = Math.round(valorTransacoes * 100) / 100

            return valorTransacoes === movimentacao.valor;
        }*/
    },
    mounted() {
        document.title = "Caixa - JF Assessoria Rural"
        this.getFechamentos().then(()=>{
            if(this.$route.params.id){
                let fechamentoId = parseInt(this.$route.params.id);
                if(fechamentoId === -1){
                    this.fechamento = {
                        id: -1,
                        nome: "Aberto",
                        dataFechamento: null,
                    }
                }else{
                    this.fechamento = this.fechamentos.find(f => f.id === parseInt(this.$route.params.id));
                }
                this.getMovimentacoesPorFechamento(fechamentoId)
            }else{
                this.fechamento = {
                    id: -1,
                    nome: "Aberto",
                    dataFechamento: null,
                }
                this.getMovimentacoesPorFechamento(this.fechamento.id)
            }
            document.title = "Caixa "+this.fechamento.nome+" - JF Assessoria Rural"
        });

    }
}
</script>

<style scoped>

</style>