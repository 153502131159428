<template>
    <v-dialog v-model="dialog" scrollable persistent width="900px">
        <v-card v-if="dialog">
            <v-card-title>Fechar Caixa</v-card-title>
            <v-card-text class="pb-0">
                <v-row no-gutters>
                    <v-col cols="6">
                        <v-form ref="form1" v-model="form1Valid" lazy-validation>
                            <v-text-field v-model="nome" label="Nome"
                                          placeholder=" " maxlength="100" :rules="requiredRules"
                                          required></v-text-field>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-simple-table fixed-header height="350" >
                            <template v-slot:default>
                                <thead>
                                <tr style="white-space: nowrap">
                                    <th>
                                        <v-checkbox v-model="allChecked" readonly @click="selectAll" :indeterminate="!hasAllSameValue"></v-checkbox>
                                    </th>
                                    <th class="text-left">
                                        Data
                                    </th>
                                    <th class="text-left">
                                        Categoria
                                    </th>
                                    <th class="text-left">
                                        Item
                                    </th>
                                    <th class="text-left" style="width: 100%">
                                        Descrição
                                    </th>
                                    <th class="text-left">
                                        Valor
                                    </th>
                                    <th class="text-left">
                                        Pagamento
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(movimento, index) in movimentos" :key="index" style="white-space: nowrap">
                                    <td>
                                        <v-checkbox class="mt-0"
                                                    :disabled="!movimentacaoPodeSerFechada(movimento)"
                                                    v-model="movimento.isSelected"
                                                    hide-details
                                                    @click.stop=""></v-checkbox>
                                    </td>
                                    <td class="grey--text darken-3 caption">
                                        {{ movimento.createdAt | moment("DD/MM/YYYY HH:mm") }}
                                    </td>
                                    <td>
                                        <v-chip color="teal lighten-4" x-small  label
                                                class="text-uppercase font-weight-medium">
                                            {{movimento.item.categoria.nome}}
                                        </v-chip>
                                    </td>
                                    <td>
                                        <v-chip color="blue" x-small dark label
                                                class="text-uppercase font-weight-medium">
                                            {{movimento.item.nome}}
                                        </v-chip>
                                    </td>
                                    <td>
                                        <template v-if="movimento.item.id === 29">
                                            <a href="" @click.prevent="showNotasFiscais(movimento.id)">
                                                {{movimento.descricao}}
                                            </a>
                                        </template>
                                        <template v-else>
                                            {{movimento.descricao}}
                                        </template>
                                    </td>
                                    <td>
                            <span :class="movimento.valor > 0 ? 'indigo--text' : 'red--text'">
                                {{ movimento.valor | formatNumber }}
                            </span>
                                    </td>
                                    <td>
                                        <v-chip v-if="movimento.pagamento" color="grey lighten-3" small >
                                            {{ movimento.pagamento.dataPagamento | moment("DD/MM/YYYY") }}
                                        </v-chip>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <div class="pa-3" style="width: 100%">
                    <v-btn text @click="closeDialog">Cancelar</v-btn>

                    <div class="float-right">
                        <v-btn color="primary" :disabled="!hasSomeSelected" text depressed @click="fechar" >
                            Fechar
                        </v-btn>
                    </div>

                </div>
            </v-card-actions>
            <v-overlay absolute :value="loading" color="white">
                <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "FecharCaixaDialog",
    data() {
        return {
            dialog: false,
            loading: false,
            form1Valid: true,
            requiredRules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            nome: null,
            movimentos: [],
            allChecked: false,
        }
    },
    computed:{
        hasSomeSelected(){
            return this.movimentos.find(movimento => movimento.isSelected)
        },
        hasAllSameValue(){
            let firstValue;
            for(let i = 0; i < this.movimentos.length; i++){
                if(i === 0){
                    firstValue = this.movimentos[i].isSelected;
                }else{
                    if(firstValue !== this.movimentos[i].isSelected){
                        return false;
                    }
                }
            }
            this.allChecked = firstValue;
            return true;
        }
    },
    methods: {
        openDialog() {
            this.dialog = true;
            this.getMovimentacoes()
        },
        closeDialog() {
            this.dialog = false;
        },
        getMovimentacoes(){
            this.$apollo.query({
                query : gql`query($id: Int!) {
                    movimentacoes:movimentacoesPorFechamento(id: $id){
                        id
                        descricao
                        valor
                        createdAt
                        item{
                            id
                            nome
                            categoria{
                                id
                                nome
                                tipo{
                                    id
                                }
                            }
                        }
                        pagamento{
                            id
                            dataPagamento
                            arquivos{
                                id
                                nome
                                createdAt
                                tags{
                                    id
                                    nome
                                }
                            }
                        }
                        arquivos{
                            id
                            nome
                            createdAt
                            tags{
                                id
                                nome
                            }
                        }
                    }
                }`,
                variables: {
                    id: -1
                }
            }).then((result) => {
                this.movimentos = result.data.movimentacoes.map(movimentacao => {
                    movimentacao.isSelected = false;
                    return movimentacao;
                });
            }).finally(()=>{
            });
        },
        movimentacaoPodeSerFechada(movimento){
            return movimento.pagamento && this.$moment(movimento.pagamento.dataPagamento).isSameOrBefore(new Date())
        },
        selectAll(){
            this.movimentos.forEach(movimento => {
                if(this.movimentacaoPodeSerFechada(movimento)){
                    movimento.isSelected = !movimento.isSelected;
                }
            })
        },
        fechar(){
            if (!this.$refs.form1.validate()) {
                return false
            }
            let ids = this.movimentos
                .filter(movimento => movimento.isSelected)
                .map(movimentacao => movimentacao.id);

            this.$apollo.mutate({
                mutation: gql`mutation($nome: String!, $movimentacoesIds: [Int!]!) {
                    fechamento:fecharCaixa(nome: $nome, movimentacoesIds: $movimentacoesIds){
                        id
                        nome
                    }
                }`,
                variables: {
                    nome: this.nome,
                    movimentacoesIds: ids,
                },
            }).then((result) => {
                this.$emit('saved', result.data.fechamento);
                this.closeDialog();
            }).catch((error) => {
                let message = error.graphQLErrors[0].message.split(':')[1];
                this.$dialog.error({title: 'Ops!', text: message})
            }).finally(()=>{
                this.loading = false
            });
        }
    }
}
</script>

<style scoped>

</style>